import React from 'react'
import { Helmet } from 'react-helmet'
import '../../styles/page.less'
import Logo from '../../images/logo.svg'
import img1 from './img/img1.jpg'
import img2 from './img/img2.jpg'
import img3 from './img/img3.jpg'
import img4 from './img/img4.jpg'
import img5 from './img/img5.jpg'
import img6 from './img/img6.jpg'
import img7 from './img/img7.jpg'
import img8 from '../kak-zarabotat-na-ipoteke/img/share.jpg'

const DESCRIPTION =
  'Узнай, как стать ипотечным брокером. Что для этого нужно? Как искать клиентов? Как оформлять ипотечные сделки брокеру!'

const HowToBecomeBroker = () => {
  return (
    <>
      <Helmet>
        <title>
          Как стать ипотечным брокером - инструкция, права и обязанности, поиск
          клиентов для ипотечного брокера с нуля
        </title>

        <meta name="description" content={DESCRIPTION} />
        <meta property="og:description" content={DESCRIPTION} />
      </Helmet>

      <div className="page">
        <div className="page--header">
          <a href="/" target="_blank">
            <img src={Logo} alt={'logo'} loading="lazy" />
          </a>
        </div>
        <article className="page--content">
          <h1>Как стать ипотечным брокером</h1>
          <p>
            ​Ипотечный брокер&nbsp;&mdash; новая (сравнительно) для России
            профессия, которая тем не&nbsp;менее становится все более
            популярной. Это специалист, помогающий клиентам выбрать подходящую
            для них программу ипотеки (но&nbsp;не&nbsp;только; далее
            мы&nbsp;поговорим об&nbsp;этом). Зачем нужна сторонняя помощь?
            Давайте посмотрим.
          </p>
          <p>
            <img src={img1} alt="Как стать ипотечным брокером" loading="lazy" />
          </p>
          <p>
            Человек, который хочет взять в&nbsp;ипотеку квартиру или дом,
            сталкивается с&nbsp;необходимостью выбрать наиболее выгодную для
            себя программу ипотечного кредитования. Ведь сумма экономии может
            быть довольно существенной: каждый&nbsp;1% выгоды запросто может
            равняться сотням тысяч рублей. При этом количество таких программ,
            доступных в&nbsp;вашем регионе, может доходить до&nbsp;500. Это
            очень затратная по&nbsp;времени задача&nbsp;&mdash; изучить
            их&nbsp;все или хотя&nbsp;бы часть. Кроме того, часто для анализа
            условий ипотеки нужны основные экономические и&nbsp;юридические
            знания, которых у&nbsp;многих просто нет.
          </p>
          <p>
            Дополнительная сложность&nbsp;&mdash; у&nbsp;каждого банка есть свои
            критерии оценки благонадежности заёмщика. Часто человек тратит
            время, выбирает оптимальную ипотеку, но&nbsp;конкретный банк
            отказывает в&nbsp;её&nbsp;получении. И&nbsp;второй отказывает.
            И&nbsp;третий. Это может случиться из-за того, что профиль заёмщика
            не&nbsp;подходит под их&nbsp;требования, а&nbsp;может, просто из-за
            неправильной подачи информации в&nbsp;заявке на&nbsp;кредит.
            Колоссальных затрат времени и&nbsp;испорченных нервов можно
            было&nbsp;бы избежать, прибегнув к&nbsp;помощи опытного специалиста
            по&nbsp;ипотеке.
          </p>
          <section className="page--section">
            <h2>Чем занимается ипотечный брокер?</h2>
            <p>
              <img
                src={img2}
                alt="Чем занимается ипотечный брокер?"
                loading="lazy"
              />
            </p>
            <p className="ul-header">
              Брокер по&nbsp;ипотеке подбирает для заёмщика программу
              кредитования, анализируя и&nbsp;учитывая сразу комплекс факторов:
            </p>
            <ul>
              <li>
                объект ипотеки, т.&nbsp;е. что именно хочет приобрести клиент
                (дом, дачу, квартиру), в&nbsp;каком регионе, на&nbsp;первичном
                или вторичном рынке недвижимости и&nbsp;т.&nbsp;д. Это позволяет
                сразу отбросить предложения, по&nbsp;которым оформить ипотеку
                на&nbsp;такой объект нельзя;
              </li>
              <li>
                условия программ банков&nbsp;&mdash; процентные ставки,
                комиссии, срок кредитования, размер ежемесячного платежа, общая
                сумма переплаты и&nbsp;другие нюансы&nbsp;&mdash; так, чтобы они
                были максимально выгодны клиенту;
              </li>
              <li>
                профиль клиента&nbsp;&mdash; ежемесячный доход и&nbsp;расходы,
                возможность залога, поручительства, привлечения созаёмщика,
                кредитная история и&nbsp;другие параметры. Опытный брокер
                не&nbsp;только знает официальные критерии, которым должен
                соответствовать потенциальный заёмщик для положительного решения
                в&nbsp;том или ином банке. Он&nbsp;также в&nbsp;курсе
                и&nbsp;некоторых негласных правил, например, что в&nbsp;этом
                банке предпринимателю или фрилансеру скорее всего в&nbsp;ипотеке
                откажут.
              </li>
            </ul>
            <p className="ul-header">
              Помимо этой основной функции, специалист по ипотеке может также:{' '}
            </p>
            <ul>
              <li>
                подготовить документы для оформления так, чтобы повысить шансы
                на&nbsp;одобрение кредита;
              </li>
              <li>
                проверить договор, представленный банком на&nbsp;наличие
                невыгодных условий, разъяснить его пункты клиенту;
              </li>
              <li>
                сопровождать кредит, например, уладить вопросы
                по&nbsp;реструктуризации долга.
              </li>
            </ul>
          </section>

          <section className="page--section">
            <h2>Права и обязанности</h2>
            <p>
              Конкретный перечень прав и&nbsp;обязанностей зависит, во-первых,
              от&nbsp;того, работает данный специалист в&nbsp;брокерском или
              риэлтерском агентстве или сам на&nbsp;себя. В&nbsp;первом случае
              он&nbsp;будет действовать в&nbsp;рамках корпоративной должностной
              инструкции, во-втором&nbsp;&mdash; права и&nbsp;обязанности будут
              оговорены в&nbsp;договоре с&nbsp;клиентом и&nbsp;могут быть более
              обширными.
            </p>
            <p>
              <img src={img3} alt="Права и обязанности" loading="lazy" />
            </p>
            <p className="ul-header">
              Чаще всего специалист по&nbsp;ипотеке может/должен:
            </p>
            <ul>
              <li>
                проанализировать предложения банков и&nbsp;предоставить клиенту
                полную информацию об&nbsp;условиях кредитования, объяснить
                их&nbsp;минусы и&nbsp;плюсы; порекомендовать оптимальную
                программу;
              </li>
              <li>
                дать советы по&nbsp;повышению статуса клиента для улучшения
                шансов положительного ответа банка;
              </li>
              <li>подготовить документы для кредитования;</li>
              <li>проверить кредитную историю потенциального заёмщика;</li>
              <li>
                провести осмотр, фотографирование, оценку предмета залога;
              </li>
              <li>провести оценку кредитоспособности заёмщика;</li>
              <li>
                вести переговоры с&nbsp;банком от&nbsp;имени заёмщика и&nbsp;так
                далее.
              </li>
            </ul>
            <p>
              Каких-то особых юридических привилегий или, наоборот, ограничений
              в этой профессии нет.
            </p>
          </section>
          <section className="page--section">
            <h2>На чем зарабатывает ипотечный брокер?</h2>
            <p>
              <img
                src={img4}
                alt="На чем зарабатывает ипотечный брокер?"
                loading="lazy"
              />
            </p>
            <p className="ul-header">
              Стоимость услуг брокеров по ипотеке в нашей стране может
              формироваться по-разному. Чаще всего это:
            </p>
            <ul>
              <li>
                определенный процент от&nbsp;суммы одобренного кредита, причем
                он&nbsp;варьируется в&nbsp;зависимости от&nbsp;сложности задачи.
                Так, клиенты с&nbsp;плохой кредитной историей платят больше;
              </li>
              <li>
                процент от&nbsp;суммы кредита, независимо от&nbsp;того, будет
                он&nbsp;одобрен или нет. Размер процента обычно больше для
                сложных случаев, и&nbsp;меньше&nbsp;&mdash; для простых;
              </li>
              <li>
                фиксированная ставка, независимо от&nbsp;сложности задачи. Может
                разбиваться на&nbsp;отдельные составляющие: подбор
                программы&nbsp;&mdash; одна сумма, подготовка
                документов&nbsp;&mdash; вторая, представительство
                в&nbsp;банке&nbsp;&mdash; третья и&nbsp;т.&nbsp;п.
              </li>
            </ul>
            <p>
              Вопреки распространенному мнению, комиссионные от банка за
              оформление ипотеки чаще всего являются не основным доходом
              брокера, а скорее, дополнением к нему. Иначе это противоречило бы
              основной сути брокериджа, ведь тогда специалист замотивирован
              предлагать не тот банк, что выгоден клиенту, а тот, что платит
              комиссионные.
            </p>
          </section>
          <section className="page--section">
            <h2>Что нужно, чтобы стать ипотечным брокером?</h2>
            <p>
              Стать ипотечным брокером в&nbsp;РФ сейчас несложно: государство
              не&nbsp;предъявляет никаких требований к&nbsp;этой деятельности,
              она не&nbsp;требует лицензирования. Поэтому всё зависит
              от&nbsp;вашего желания и&nbsp;навыков.
            </p>
            <p>
              <img
                src={img5}
                alt="Что нужно, чтобы стать ипотечным брокером?"
                loading="lazy"
              />
            </p>
            <p className="ul-header">
              Для начала проанализируйте, есть&nbsp;ли у&nbsp;вас качества,
              которые необходимы специалисту такого профиля:
            </p>
            <ul>
              <li>аналитический склад ума;</li>
              <li>пунктуальность, внимательность;</li>
              <li>хорошая память;</li>
              <li>умение работать с&nbsp;людьми, вежливость.</li>
            </ul>
            <p>
              Можно&nbsp;ли стать брокером с&nbsp;нуля? Конечно, это вполне
              реально. Можно пройти специальные курсы по&nbsp;этой профессии
              и&nbsp;получить сертификат.
            </p>
            <p className="ul-header">Если&nbsp;же у&nbsp;вас есть:</p>
            <ul>
              <li>экономическое, финансовое или юридическое образование;</li>
              <li>опыт работы в&nbsp;банке кредитным специалистом;</li>
              <li>личные связи с&nbsp;сотрудниками банков,</li>
            </ul>
            <p>
              &mdash;&nbsp;то&nbsp;ваш старт в&nbsp;этой профессии будет
              предельно простым.
            </p>
            <p>
              Если вы&nbsp;хотите работать самостоятельно,
              а&nbsp;не&nbsp;в&nbsp;брокерской или риэлтерской конторе, вам
              потребуется регистрация в&nbsp;качестве&nbsp;ИП.
            </p>
          </section>
          <section className="page--section">
            <h2>Что необходимо знать брокеру по ипотеке?</h2>
            <p>
              <img
                src={img6}
                alt="Что необходимо знать брокеру по ипотеке?"
                loading="lazy"
              />
            </p>
            <p className="ul-header">
              Чтобы стать брокером по&nbsp;ипотеке, вам потребуются основные
              знания по&nbsp;экономике, финансам, финансовой математике,
              делопроизводству и&nbsp;юриспруденции. Ведь вам нужно будет:
            </p>
            <ul>
              <li>разбираться в&nbsp;гражданском, банковском праве;</li>
              <li>
                уметь рассчитывать кредитные платежи, суммы переплаты
                и&nbsp;другие параметры ипотеки;
              </li>
              <li>составлять и&nbsp;проверять договоры;</li>
              <li>
                вести переписку, проверять подлинность документов и&nbsp;многое
                другое.
              </li>
            </ul>
            <p className="ul-header">Вам потребуются знания:</p>
            <ul>
              <li>психологии и&nbsp;социологии;</li>
              <li>этики делового общения;</li>
              <li>кредитного рынка;</li>
              <li>методик сбора и&nbsp;анализа информации;</li>
              <li>
                этапов и&nbsp;процедур исполнительного и&nbsp;судебного
                производства; и&nbsp;это далеко не&nbsp;полный перечень.
              </li>
            </ul>
            <p>
              Впрочем, все это можно изучить и&nbsp;в&nbsp;рамках
              самообразования, особенно, если вы&nbsp;собираетесь работать как
              свободный специалист.
            </p>
            <p>
              Дальше&nbsp;&mdash; дело опыта. Вам потребуется изучить программы
              ипотеки банков в&nbsp;вашем регионе, методики оценки
              платёжеспособности клиента, постоянно следить за&nbsp;новыми
              предложениями и&nbsp;обновлениями. Кроме того, нужно всегда быть
              в&nbsp;курсе изменений в&nbsp;законодательстве (в&nbsp;сфере
              кредитования, операций с&nbsp;недвижимостью и&nbsp;других
              смежных).
            </p>
            <p>
              Также специалисту этого профиля важно наладить хорошие отношения
              с&nbsp;сотрудниками банков, занимающимися приёмом заявок
              на&nbsp;ипотеку&nbsp;&mdash; это облегчит представление интересов
              клиента, поможет в&nbsp;получении актуальной информации
              о&nbsp;программах.
            </p>
          </section>
          <section className="page--section">
            <h3>Влияет ли ипотечный брокер на решение банка</h3>
            <p className="ul-header">
              Вопреки распространенному заблуждению брокер не&nbsp;может
              благодаря своим связям или каким-то рычагам влияния
              &laquo;надавить&raquo; на&nbsp;банк или
              &laquo;посодействовать&raquo; получению ипотеки. Однако в&nbsp;его
              силах повлиять на&nbsp;положительный ответ вполне законными,
              профессиональными способами:
            </p>
            <ul>
              <li>дать рекомендации заёмщику по&nbsp;повышению его статуса;</li>
              <li>
                посоветовать, как в&nbsp;наиболее выгодном свете представить
                информацию о&nbsp;себе, не&nbsp;нарушая закон, какие документы
                для этого представить дополнительно;
              </li>
              <li>
                подготовить нужные документы для клиента и&nbsp;правильно
                заполнить анкету;
              </li>
              <li>
                провести предварительную оценку платёжеспособности своего
                клиента, проанализировать, для каких банков она будет
                приемлемой, и&nbsp;рекомендовать подавать заявку в&nbsp;самый
                выгодный из&nbsp;них и&nbsp;т.&nbsp;д.
              </li>
            </ul>
          </section>
          <section className="page--section">
            <h2>Откуда брать клиентов</h2>
            <p>
              <img src={img7} alt="Откуда брать клиентов" loading="lazy" />
            </p>
            <p className="ul-header">
              Начинающему брокеру проще всего прибегнуть к&nbsp;помощи рекламы,
              оффлайн и&nbsp;в&nbsp;интернете, куда входит:
            </p>
            <ul>
              <li>
                размещение баннеров и&nbsp;объявлений вблизи банков, новостроек,
                в&nbsp;транспорте;
              </li>
              <li>
                размещение рекламных материалов в&nbsp;офисах застройщиков
                и&nbsp;агентств недвижимости;
              </li>
              <li>создание собственного сайта;</li>
              <li>
                поисковая оптимизация и&nbsp;контекстная реклама на&nbsp;поиске;
              </li>
              <li>
                размещение рекламных баннеров на&nbsp;сайтах риэлтеров, продажи
                недвижимости, компаний-застройщиков&nbsp;&mdash;
                по&nbsp;договоренности с&nbsp;ними.
              </li>
            </ul>
            <p>
              В дальнейшем начнёт работать «сарафанное радио»: ваши довольные
              клиенты начнут рекомендовать вас друзьям и знакомым.
            </p>
          </section>
          <section className="page--section">
            <h2>Регулирование деятельности</h2>
            <p>
              Как мы&nbsp;уже упоминали выше, никаких специальных
              законодательных актов, регулирующих работу брокера
              по&nbsp;ипотеке, в&nbsp;РФ пока нет. Деятельность ведётся
              в&nbsp;рамках гражданского права.
            </p>
            <p>
              Специалист по&nbsp;ипотеке обязан соблюдать условия договора
              с&nbsp;клиентом и&nbsp;обычные, стандартные для всех нормы
              законов.
            </p>
          </section>
          <section className="page--section">
            <h2>Стоимость услуг ипотечных брокеров</h2>
            <p>
              Обычно вознаграждение специалиста составляет в&nbsp;среднем 0,35%
              от&nbsp;суммы ипотеки, если он&nbsp;работает по&nbsp;схеме
              процента от&nbsp;сделки с&nbsp;банком. Если&nbsp;же
              мы&nbsp;говорим о&nbsp;фиксированных ставках, то&nbsp;для разных
              регионов она может варьироваться довольно широко.
            </p>
            <p>
              Доход в&nbsp;брокеридже ипотеки в&nbsp;РФ
              от&nbsp;20&nbsp;тыс.&nbsp;до&nbsp;180 тыс рублей (предела,
              конечно, нет), в&nbsp;среднем специалист получает
              65&minus;70&nbsp;тыс. рублей.
            </p>
          </section>
          <section className="page--section">
            <h2>Сервисы и инструменты для работы ипотечным брокером</h2>
            <p>
              Как вы&nbsp;уже, вероятно, поняли из&nbsp;вышеописанного, работа
              у&nbsp;брокера по&nbsp;ипотеке не&nbsp;самая простая&nbsp;&mdash;
              необходимо оперировать большим объемом информации, сравнивать
              предложения, подавать заявки в&nbsp;множество банков, потом
              отслеживать решения по&nbsp;ним. Отличный выходом станет
              использование сервиса &laquo;
              <a href="/" target="_blank">
                Ипотека.Центр
              </a>
              &raquo;. Это платформа, которая автоматизирует большинство типовых
              операций специалиста по&nbsp;ипотеке. Больше не&nbsp;понадобятся
              расчёты в&nbsp;эксель и&nbsp;множество открытых окон.
            </p>
            <p>
              <img
                src={img8}
                alt="Сервисы и инструменты для работы ипотечным брокером"
                loading="lazy"
              />
            </p>
            <p>
              Большинство аналогичных сервисов на&nbsp;рынке предлагают
              автоматизацию только части работы специалиста, например, оценку
              платежеспособности клиента. &laquo;Ипотека.Центр&raquo; позволяет
              пройти все этапы сделки в&nbsp;одном окне. Ведь к&nbsp;платформе
              подключены и&nbsp;банки, и&nbsp;оценочные, и&nbsp;страховые
              кампании.
            </p>
            <p className="ul-header">
              На&nbsp;платформе вы&nbsp;можете в&nbsp;онлайн-режиме:
            </p>
            <ul>
              <li>
                подобрать программу ипотеки для вашего клиента по&nbsp;заданным
                параметрам в&nbsp;умном калькуляторе;
              </li>
              <li>
                заполнить единую анкету и&nbsp;отправить ё&nbsp;в&nbsp;качестве
                заявки на&nbsp;кредит во&nbsp;все банки, подключенные
                к&nbsp;платформе. Это существенно экономит время, ведь обычно
                у&nbsp;каждого банка своя форма заявки;
              </li>
              <li>
                здесь&nbsp;же в&nbsp;программе мониторить&nbsp;&mdash;
                одобрена&nbsp;ли заявка;
              </li>
              <li>заказать оценку объекта;</li>
              <li>застраховать недвижимость;</li>
              <li>
                назначить встречу клиента с&nbsp;представителем банка для
                оформления кредита.
              </li>
            </ul>
            <p className="ul-header">Кроме того: </p>
            <ul>
              <li>
                на&nbsp;платформе предусмотрено обучение работе с&nbsp;ней;
              </li>
              <li>за&nbsp;вами закрепляется персональный куратор;</li>
              <li>
                регулярно проводятся мастер-классы, бизнес-завтраки
                и&nbsp;другие мероприятий по&nbsp;обмену опытом с&nbsp;командой
                &laquo;
                <a href="/" target="_blank">
                  Ипотека.Центр
                </a>
                &raquo;&nbsp;&mdash; опытными экспертами по&nbsp;недвижимости.
              </li>
            </ul>
            <p>
              Попробуйте! Вы&nbsp;увидите, насколько можно упростить свою работу
              при использовании правильных инструментов.
            </p>
          </section>
        </article>
      </div>
    </>
  )
}

export default HowToBecomeBroker
